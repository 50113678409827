import React, { useState, useEffect } from 'react';
import { bool, node, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import CountryField from './CountryField';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import * as validators from '../../../util/validators';
import { getPropsForCustomUserFieldInputs } from '../../../util/userHelpers';

import { Form, PrimaryButton, FieldTextInput, CustomExtendedDataField } from '../../../components';

import FieldSelectUserType from '../FieldSelectUserType';
import UserFieldDisplayName from '../UserFieldDisplayName';
import UserFieldPhoneNumber from '../UserFieldPhoneNumber';

import css from './SignupForm.module.css';

const getSoleUserTypeMaybe = userTypes =>
  Array.isArray(userTypes) && userTypes.length === 1 ? userTypes[0].userType : null;

const groupCustomFields = userFields => {
  const result = [];
  const batchSize = 3;
  if (userFields?.length) {
    for (let i = 0; i < userFields.length; i += batchSize) {
      result.push(userFields.slice(i, i + batchSize));
    }
  }
  return result;
};

function extractCountryFromUserFields(fieldArray) {
  const foundObject = fieldArray.find(field => field.fieldConfig.key === 'usercountry');
  const restOfArray = fieldArray.filter(field => field.fieldConfig.key !== 'usercountry');
  return {
    countryField: foundObject ? foundObject : null,
    restOfUserFields: restOfArray,
  };
}

const EXCLUDE_FROM_NATIVE = ['acuitylink'];
const EXCLUDE_FROM_NATIVE_NON_MOBILE = ['traveltime'];
const EXCLUDE_FROM_ACUITY = ['traveltime'];

const excludeForUserType = ({
  userType,
  pub_stylisttype: stylistType,
  pub_Acuity_yes_no: hasAcuity,
}) => {
  if (userType === 'customer') return [];
  let excludeList = [];
  if (hasAcuity === 'No') {
    excludeList = excludeList.concat(EXCLUDE_FROM_NATIVE);
    if (stylistType !== 'mobilestylist') {
      excludeList = excludeList.concat(EXCLUDE_FROM_NATIVE_NON_MOBILE);
    }
  } else {
    excludeList = excludeList.concat(EXCLUDE_FROM_ACUITY);
  }
  return excludeList;
};

const excludeObjectsWithKeys = (data, keysToExclude) => {
  return data?.filter(item => !keysToExclude.includes(item.fieldConfig.key)) || [];
};

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    initialValues={{ userType: props.preselectedUserType || getSoleUserTypeMaybe(props.userTypes) }}
    render={formRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        termsAndConditions,
        preselectedUserType,
        userTypes,
        userFields,
        values,
        authenticationContainerRef,
      } = formRenderProps;

      const { userType } = values || {};

      //country
      const hasSelectedCountry = !!values.pub_usercountry;

      // email
      const emailRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.emailRequired',
        })
      );
      const emailValid = validators.emailFormatValid(
        intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        })
      );

      // password
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      // Custom user fields. Since user types are not supported here,
      // only fields with no user type id limitation are selected.
      const userFieldProps = getPropsForCustomUserFieldInputs(userFields, intl, userType);

      const noUserTypes = !userType && !(userTypes?.length > 0);
      const userTypeConfig = userTypes.find(config => config.userType === userType);
      const showDefaultUserFields = userType || noUserTypes;
      const showCustomUserFields = (userType || noUserTypes) && userFieldProps?.length > 0;

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const [step, setStep] = useState(1);
      const [showScrollButton, setShowScrollButton] = useState(false);

      const handleNext = () => {
        setStep(step + 1);
      };
      const handlePrevious = () => {
        setStep(step - 1);
      };

      const { countryField, restOfUserFields } = extractCountryFromUserFields(userFieldProps);

      const filteredCustomFields = excludeObjectsWithKeys(
        restOfUserFields,
        excludeForUserType(values)
      );
      const groupedCustomFields = groupCustomFields(filteredCustomFields);
      const totalSteps = groupedCustomFields.length + 1;
      const progress = ((step - 1) / (totalSteps - 1)) * 100;

      const handleScrollToBottom = () => {
        const container = authenticationContainerRef.current;
        if (container) {
          container.scrollTo({
            top: container.scrollHeight,
            behavior: 'smooth',
          });
          setShowScrollButton(false);
        }
      };

      useEffect(() => {
        if (step === totalSteps && userType === 'stylist') {
          const container = authenticationContainerRef.current;
          if (container) {
            const checkScroll = () => {
              const { scrollTop, scrollHeight, clientHeight } = container;
              const isScrolledToBottom = scrollTop + clientHeight >= scrollHeight - 10; // 10px threshold
              setShowScrollButton(!isScrolledToBottom);
            };

            checkScroll(); // Check initial scroll position
            container.addEventListener('scroll', checkScroll);

            return () => {
              container.removeEventListener('scroll', checkScroll);
            };
          }
        }
      }, [step, totalSteps, userType, authenticationContainerRef]);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldSelectUserType
            name="userType"
            userTypes={userTypes}
            hasExistingUserType={!!preselectedUserType}
            intl={intl}
          />

          {step === 1 && !!countryField ? (
            <CountryField {...countryField} userType={userType} />
          ) : null}

          {hasSelectedCountry && showDefaultUserFields && step === 1 ? (
            <div className={css.defaultUserFields}>
              <div className={css.name}>
                <FieldTextInput
                  className={css.firstNameRoot}
                  type="text"
                  id={formId ? `${formId}.fname` : 'fname'}
                  name="fname"
                  autoComplete="given-name"
                  label={intl.formatMessage({
                    id: 'SignupForm.firstNameLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.firstNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.firstNameRequired',
                    })
                  )}
                />
                <FieldTextInput
                  className={css.lastNameRoot}
                  type="text"
                  id={formId ? `${formId}.lname` : 'lname'}
                  name="lname"
                  autoComplete="family-name"
                  label={intl.formatMessage({
                    id: 'SignupForm.lastNameLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.lastNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.lastNameRequired',
                    })
                  )}
                />
              </div>
              <FieldTextInput
                type="email"
                className={css.row}
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={intl.formatMessage({
                  id: 'SignupForm.emailLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.emailPlaceholder',
                })}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />

              <UserFieldDisplayName
                formName="SignupForm"
                className={css.row}
                userTypeConfig={userTypeConfig}
                intl={intl}
              />

              <UserFieldPhoneNumber
                formName="SignupForm"
                className={css.row}
                userTypeConfig={userTypeConfig}
                intl={intl}
              />

              <FieldTextInput
                className={css.password}
                type="password"
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="new-password"
                label={intl.formatMessage({
                  id: 'SignupForm.passwordLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.passwordPlaceholder',
                })}
                validate={passwordValidators}
              />
            </div>
          ) : null}

          {hasSelectedCountry && showCustomUserFields && step > 1 ? (
            <div className={css.customFields}>
              {groupedCustomFields[step - 2].map(fieldProps => (
                <CustomExtendedDataField key={fieldProps.key} {...fieldProps} formId={formId} />
              ))}
            </div>
          ) : null}

          {hasSelectedCountry && totalSteps > 1 && (
            <div className={css.bottomWrapper}>
              <div className={css.buttonWrapper}>
                {step > 1 && (
                  <PrimaryButton type="button" onClick={handlePrevious} className={css.stepButton}>
                    Previous
                  </PrimaryButton>
                )}

                {step < totalSteps && (
                  <PrimaryButton type="button" onClick={handleNext} className={css.stepButton}>
                    Next
                  </PrimaryButton>
                )}
              </div>
              {totalSteps > 2 && (
                <div className={css.progressBar}>
                  <div className={css.progress} style={{ width: `${progress}%` }}></div>
                </div>
              )}
            </div>
          )}

          {hasSelectedCountry && step === totalSteps && (
            <div className={css.bottomWrapper}>
              {termsAndConditions}
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="SignupForm.signUp" />
              </PrimaryButton>
            </div>
          )}

          {showScrollButton && (
            <button type="button" className={css.scrollButton} onClick={handleScrollToBottom}>
              <span style={{ fontSize: '24px' }}>&#8595;</span>
            </button>
          )}
        </Form>
      );
    }}
  />
);

SignupFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  inProgress: bool,
  termsAndConditions: node.isRequired,
  preselectedUserType: string,
  userTypes: propTypes.userTypes.isRequired,
  userFields: propTypes.listingFields.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
