import React, { useState } from 'react';
import { getNames } from 'country-list';
import { FieldSelect } from '../../../components';
import styles from './SignupForm.module.css';
import { apiBaseUrl } from '../../../util/api';

const CountryField = ({
  userType,
  name,
  fieldConfig = {},
  defaultRequiredMessage = 'This field is required',
  formId,
  onCountrySelect,
}) => {
  const [selectedCountry, setSelectedCountry] = useState('');
  const [isWaitlistOpen, setIsWaitlistOpen] = useState(false);
  const [waitlistData, setWaitlistData] = useState({
    name: '',
    email: '',
    country: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  // Extract configuration from props
  const { enumOptions = [], saveConfig = {} } = fieldConfig;
  const {
    placeholderMessage = 'Select country',
    isRequired = false,
    requiredMessage = defaultRequiredMessage,
  } = saveConfig;

  // Create options for the select
  const countryOptions = enumOptions.map(option => ({
    key: option.option,
    label: option.label,
  }));

  // Handlers
  const handleCountryChange = e => {
    const value = e;
    setSelectedCountry(value);
    if (onCountrySelect) {
      onCountrySelect(value);
    }
    if (value) {
      setIsWaitlistOpen(false);
    }
  };

  const handleWaitlistInputChange = e => {
    const { name, value } = e.target;
    setWaitlistData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleWaitlistCountryChange = e => {
    const value = e;
    setWaitlistData(prev => ({
      ...prev,
      country: value,
    }));
  };

  const handleWaitlistSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    setIsSubmitting(true);

    const baseUrl = apiBaseUrl();
    try {
      const response = await fetch(`${baseUrl}/api/join-waitlist?userType=${userType}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(waitlistData),
      });

      if (!response.ok) throw new Error('Submission failed');

      setSubmitStatus('success');

      // Reset form after successful submission
      setWaitlistData({ name: '', email: '', country: '' });
      setTimeout(() => setIsWaitlistOpen(false), 3000);
    } catch (error) {
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.customField}>
      {/* Country Selection Dropdown */}
      <FieldSelect
        className={styles.countrySelect}
        name={name}
        id={formId ? `${formId}.${name}` : name}
        value={selectedCountry}
        onChange={handleCountryChange}
        required={isRequired}
      >
        <option value="" disabled>
          {placeholderMessage}
        </option>
        {countryOptions.map(({ key, label }) => (
          <option key={key} value={key}>
            {label}
          </option>
        ))}
      </FieldSelect>

      {/* Waitlist Section */}
      <div className={styles.waitlistSection}>
        <button
          type="button"
          className={styles.waitlistToggle}
          onClick={() => setIsWaitlistOpen(!isWaitlistOpen)}
        >
          Can&apos;t find your country?
        </button>

        {isWaitlistOpen && (
          <div className={styles.waitlistContent}>
            <p>
              If your country isn&apos;t listed, please enter your details below. We&apos;ll notify
              you as soon as our product is available in your region.
            </p>

            <div>
              <div className={styles.formGroup}>
                <label htmlFor="name" className={styles.label}>
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className={styles.input}
                  value={waitlistData.name}
                  onChange={handleWaitlistInputChange}
                  required
                  placeholder="Enter your full name"
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="email" className={styles.label}>
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className={styles.input}
                  value={waitlistData.email}
                  onChange={handleWaitlistInputChange}
                  required
                  placeholder="Enter your email address"
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="country" className={styles.label}>
                  Your Country
                </label>
                <FieldSelect
                  name="country"
                  id="country"
                  value={waitlistData.country}
                  onChange={handleWaitlistCountryChange}
                  required
                  placeholder="Select your country"
                >
                  <option value="" disabled>
                    Select your country
                  </option>
                  {getNames().map(country => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </FieldSelect>
              </div>

              <button
                type="submit"
                className={styles.submitButton}
                disabled={isSubmitting}
                onClick={handleWaitlistSubmit}
              >
                {isSubmitting ? 'Submitting...' : 'Join Waitlist'}
              </button>

              {submitStatus === 'success' && (
                <div className={`${styles.alert} ${styles.successAlert}`}>
                  Thanks for joining our waitlist! We&apos;ll be in touch soon.
                </div>
              )}

              {submitStatus === 'error' && (
                <div className={`${styles.alert} ${styles.errorAlert}`}>
                  Something went wrong. Please try again later.
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CountryField;
